import MultiPass from "../lib/Multipass";

const date = new Date();

const initKeywords = function () {
    $.ajax({
        url: '/themes/default/js/keywords.json?_' + date.getTime(),
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        cache: false,
        data: {}
    }).done(function( json ) {
        MultiPass().init(json);
    }).fail(function(jqXHR, textStatus, errorThrown) { console.log(errorThrown); });
}

export default initKeywords;
