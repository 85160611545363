const MultiPass = function () {
    let s;

    return {
        settings: {
            select: $(".multipass")
        },

        init: function(json) {
            s = this.settings;
            s.json = json;
            this.setOptions(s.json);
            this.update(s.json);
            this.onClick();
            this.onEnter();
        },

        setOptions: function(json) {
            var self = this;
            var index = 0;
            $.each(json, function(i, obj) {
                s.select.append('<span data-id="'+ obj.id +'" data-index="'+index+'">' + obj.value + '</span>');
                index++;
            });
        },

        update: function(json) {
            var self = this;
            $('.multi-select-search .search').keyup(function() {
                $('.multipass span').removeClass();
                var input = $(this).val();
                $.each(json, function(i, v) {
                    if (v.value.search(new RegExp("\^" + input + "", "i")) != -1) {
                        v.value = $('<div />').html(v.value).text();

                        var selected = $('.multipass span').filter(function() {
                            return $(this).text() == v.value;
                        });
                        self.selectOption(selected);
                        return false;
                    }
                });
            });
        },

        onClick: function() {
            $('.multipass span').click(function() {
                $('.multipass span').removeClass();
                $(this).addClass('active');

                if(typeof ga != "undefined") {
                    var selectedKeyword = $(this).text();
                    ga('send','event','Homepage','Keyword Selected', selectedKeyword);
                }

                window.location.href = '/category/view/id/'+ $(this).data('id') +'/letter/' + $(this).text().substr(0,1).toUpperCase();
            });
        },

        onEnter: function(){
            $('.multi-select-search .search').keypress(function(e){
                if(e.which == 13){
                    e.preventDefault();
                    if($('.multipass .active').length == 1){
                        window.location.href = '/category/view/id/'+ $('.multipass .active').data('id') +'/letter/' + $('.multipass .active').text().substr(0,1).toUpperCase();
                    }
                }
            });
        },

        selectOption: function(option) {
            $option = option;
            $('.multipass').css('overflow', 'hidden');
            var scrollHeight = $('.multipass')[0].scrollHeight;
            var clientHeight = $('.multipass')[0].clientHeight;
            var offsetHeight = $('.multipass')[0].offsetHeight;
            var noOfitems = ($('.multipass').children().length);

            var rowHeight = Math.round(scrollHeight / noOfitems);
            var position = rowHeight * ($option.data('index'));

            $('.multipass').scrollTop(position);
            $option.addClass('active');
            $('.multipass').css('overflow-y', 'scroll');
            $('.multipass').css('overflow-x', 'hidden');
        }

    };
};

export default MultiPass;
